import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { SpinnerCircular } from 'spinners-react'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../utils/htmlSerializer'
import HubspotForm from 'react-hubspot-form'

const FormAssemblyEmbed = loadable(() =>
  pMinDelay(import('../Common/FormAssemblyEmbed'), 2000),{
    fallback:
      <div className="flex flex-col justify-center items-center mt-6">
        <SpinnerCircular color="#bb0000" secondaryColor="#FEF2F2" size="70" />
        <h2 className="text-center text-sm italic mt-3">Loading Form</h2>
      </div>
  }
)

const CosponsorSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query CosponsorSectionUpdateQuery {
      prismicRequestFormsCosponsor {
        data {
          section_title
          section_description {
            richText
          }
          form_type
          form_id
        }
      }
    }
  `);

  const doc = data.prismicRequestFormsCosponsor

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6 mb-16">
        <h2 className="text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold">
          {doc.data.section_title}
        </h2>

        <div className="mt-6">
          <RichText
            render={doc.data.section_description.richText}
            htmlSerializer={htmlSerializer}
          />
        </div>
      </div>

      {doc.data.form_type === "Form Assembly"
        ?
        <div className="max-w-screen-md bg-white mt-16 shadow-md rounded-md pt-2 pb-12">
          <FormAssemblyEmbed formId={doc.data.form_id} mdWidth="300" smWidth="330" xsWidth="280" height="600" />
        </div>
        : doc.data.form_type === "HubSpot"
        ?
        <div className="max-w-screen-md bg-white mt-16 shadow-md rounded-md p-6">
          <HubspotForm
            portalId='23182726'
            formId={doc.data.form_id ? doc.data.form_id : 'dfa38579-9c57-4e76-8e3d-5fd33b0f232e'}
            loading={<div className='italic'>Loading form...</div>}
          />
        </div>
        :
        null
      }
    </>
  )
}

export default CosponsorSectionUpdate