import React from 'react';
import { compose } from 'recompose';
import BackButton from '../../components/Common/BackButton';

import DashboardWrapper from '../../components/Dashboard/DashboardWrapper';
import Layout from '../../components/layout';
import CosponsorSectionUpdate from '../../components/Sections/CosponsorSectionUpdate';
import {
  withAuthorization,
} from '../../components/Session';
import HeadData from "../../data/HeadData"

const CosponsorPageBase = () => (
  <DashboardWrapper pageName='Cosponsor'>
    <div className="relative pt-16 pb-32 md:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <CosponsorSectionUpdate />

        <BackButton buttonLink="/requests" />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const CosponsorPage = compose(
  withAuthorization(condition),
)(CosponsorPageBase);

const Cosponsor = () => (
  <Layout>
    <HeadData title='Cosponsor' />
    <CosponsorPage />
  </Layout>
)

export default Cosponsor
